<script setup>
    import __ from '@components/../mixins/locale'
    import axios from 'axios'
    import { throttle } from 'throttle-debounce'
    import { onMounted, ref } from 'vue'

    const props = defineProps({
        preload: {
            type: Boolean,
            default() {
                return false
            },
            required: false
        },
        inBody: {
            type: Boolean,
            default() {
                return false
            },
            required: false
        },
        language: {
            type: String,
            default() {
                return 'en';
            },
            required: false
        },
        positionRef: {
            type: String,
            default() {
                return ''
            },
            required: false
        },
        siteDomain: {
            type: String,
            required: true
        },
        count: {
            type: Number,
            default() {
                return 1
            },
            required: false
        },
        gender: {
            type: String,
            default() {
                return 'c'
            },
            required: false
        },
        ageRange: {
            type: String,
            default() {
                return ''
            },
            required: false
        },
        public: {
            type: Number,
            default() {
                return 1
            },
            required: false
        },
        randomise: {
            type: Number,
            default() {
                return 1
            },
            required: false
        },
        currentUrl: {
            type: String,
            default() {
                return ''
            },
            required: false
        },
        genreId: {
            type: Number,
            default() {
                return ''
            },
            required: false
        },
        showRoomSubject: {
            type: Boolean,
            default() {
                return false
            },
            required: false
        }
    });

    const performerElement = ref(null);

    const performerType = ref('chaturbate');

    const mdhAffiliateCode = ref('?ats=eyJhIjozMDcwNDQsImMiOjU4OTczMDQ2LCJuIjoyMSwicyI6MjQyLCJlIjo4NjAsInAiOjJ9');
    
    const performerData = ref('');
    
    const mobileDevice = ref(isMobile());

    const getPerformers = () => {
        axios.get(`/api/performers`, {
            params: {
                type: performerType.value,
                count: props.count,
                gender: props.gender,
                ageRange: props.ageRange,
                public: props.public,
                randomise: props.randomise,
                siteDomain: props.siteDomain,
                mobile: mobileDevice.value,
                language: props.language,
                positionRef: props.positionRef,
                genreId: props.genreId
            }
        }).then(response => {
            performerData.value = response.data;
        });
    }

    const saveUserAction = (contextualId, context, action) => {
        axios.get(`/api/user-actions`, {
            params: {
                contextualId: contextualId + 1,
                context: context,
                action: action,
                currentUrl: props.currentUrl,
                positionRef: props.positionRef
            }
        });
    }

    const myDirtyHobbyPerformerUrl = (performer) => {
        return performer.url + mdhAffiliateCode.value;
    }

    const getRoute = (route, overrideRoute) => {
        if (window.routes[overrideRoute]) {
            return window.routes[overrideRoute]
        }
        return window.routes[route];
    }
    
    onMounted(() => {
        var randomNumber = Math.random();
        if(props.siteDomain === 'sexgeschichten.club') {
            if (randomNumber < 0.1 && props.count == 1) {
                performerType.value = 'adverts';
            } else if (randomNumber >= 0.1 && randomNumber <= 0.55) {
                performerType.value = 'myDirtyHobby';
            }
        } else if (Math.random() <= 0.1 && props.count == 1) {
            performerType.value = 'adverts';
        }
        
        if (performerType.value === 'adverts') {
            return false;
        }

        if(props.preload) {
            getPerformers();
        } else {
            const throttleFunc = throttle(1000, () => {
                if(isInViewport(performerElement.value)) {
                    getPerformers();
                    window.removeEventListener('scroll', throttleFunc);
                }
            });
            window.addEventListener('scroll', throttleFunc);
        }
    })
</script>

<template>
    <div ref="performerElement" v-bind:class="[{ 'card-columns performer-columns': !inBody, 'in-body': inBody, 'in-body-top': preload }]">
        <template v-if="performerType === 'chaturbate'">
            <div v-for="(performer, index) in performerData">
                <a @click="saveUserAction(index, performer, 'CHATURBATE_CLICKTHROUGH')" :href="performer.chat_room_url_revshare" target="_blank" class="card contrast-block performer-block" :style="'background-color: #000; background-image: url(' + performer.image_url + ')'">
                    <div class="chat-logo">
                        <svg style="width: 20px" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="comments" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M416 192c0-88.4-93.1-160-208-160S0 103.6 0 192c0 34.3 14.1 65.9 38 92-13.4 30.2-35.5 54.2-35.8 54.5-2.2 2.3-2.8 5.7-1.5 8.7S4.8 352 8 352c36.6 0 66.9-12.3 88.7-25 32.2 15.7 70.3 25 111.3 25 114.9 0 208-71.6 208-160zm122 220c23.9-26 38-57.7 38-92 0-66.9-53.5-124.2-129.3-148.1.9 6.6 1.3 13.3 1.3 20.1 0 105.9-107.7 192-240 192-10.8 0-21.3-.8-31.7-1.9C207.8 439.6 281.8 480 368 480c41 0 79.1-9.2 111.3-25 21.8 12.7 52.1 25 88.7 25 3.2 0 6.1-1.9 7.3-4.8 1.3-2.9.7-6.3-1.5-8.7-.3-.3-22.4-24.2-35.8-54.5z"></path></svg>
                        Online - Chat Now
                    </div>
                    <span class="username">
                        <strong>{{ performer.username }}</strong>
                        <span v-if="showRoomSubject">
                        <br />
                        {{ performer.room_subject }}
                        </span>
                    </span>
                </a>
            </div>
        </template>
        <template v-else-if="performerType === 'myDirtyHobby'">
            <div v-for="(performer, index) in performerData">
                <a @click="saveUserAction(index, performer, 'MYDIRTYHOBBY_CLICKTHROUGH')" :href="myDirtyHobbyPerformerUrl(performer)" target="_blank" class="card contrast-block performer-block" :style="'background-color: #000; background-image: url(' + performer.img_small + ')'">
                    <span class="username">
                        <strong>{{ performer.title }}</strong>
                        <template v-if="showRoomSubject">
                            <br />
                            <span v-html="performer.description"></span>
                        </template>
                    </span>
                    <div style="text-align: center; margin-top: 50px;"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="play-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 40px"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"></path></svg></div>
                </a>
            </div>
        </template>
        <template v-else-if="performerType === 'adverts'">
            <div v-if="Math.random() < 0.5" class="card contrast-block story-block card-body" style="background-color: teal; width: 100%; max-width: 370px;">
                <h2 class="card-title">
                    <a :href="getRoute('adverts', 'my_adverts')" class="stretched-link">
                        {{ __('Advertise for Free') }}
                    </a>
                </h2>
                <ul>
                    <li style="margin: 20px 0">{{ __('Hookup / Meetup') }}</li>
                    <li style="margin: 20px 0">{{ __('Offer services') }}</li>
                    <li style="margin: 20px 0">{{ __('Sell something') }}</li>
                </ul>
                <a :href="getRoute('adverts', 'my_adverts')" class="btn btn-dark btn-md btn-continue">{{ __('Place an Advert') }}</a>
            </div>
            <div v-else class="card contrast-block story-block card-body" style="width: 100%; max-width: 370px; background-color: darkblue;">
                <h2 class="card-title">
                    <a :href="getRoute('story_submit')" class="stretched-link">
                        {{ __('Got a great sexy story?') }}
                    </a>
                </h2>
                <p style="margin: 30px 0">
                    {{ __("What's your fetish or kink?") }}<br>
                    {{ __('Enjoy it and have fun') }}...
                </p>
                <a :href="getRoute('story_submit')" class="btn btn-dark btn-md btn-continue">{{ __('Submit a story') }}</a>
            </div>
        </template>
    </div>
</template>

<style lang="scss">
    @media (min-width: 992px) {
        .performer-columns {
            column-count: 4;
        }
    }
    @media (max-width: 991px) {
        .performer-columns {
            columns: 2;
        }
    }
    .performer-block {
        height: 250px;
        width: 100%;
        background-size: cover;
        background-position: center;
        .chat-logo {
            width: 40px;
            position: absolute;
            top: 5px;
            right: 5px;
            background: #28a745;
            padding: 5px;
            border-radius: 5px;
        }
        .username {
            background: rgb(0 0 0 / .7);
            width: 100%;
            display: block;
            bottom: 0;
            position: absolute;
            text-align: center;
            padding: 10px;
            font-size: 0.8em;
        }
    }
    .in-body .performer-block {
        width: 250px;
    }
    .in-body-top {
        z-index: 1;
        position: relative;
        margin-top: 8px !important;
        min-width: 250px;
        min-height: 250px;
    }
</style>